import "@/lib/helper/gtmInit.js";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Meta from "vue-meta";
import VueSession from "vue-session";
import { VueFilter } from "./utils/VueFilter.ts";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vueDebounce from "vue-debounce";

import { icons } from "@/lib/icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import cfg from "@/config/config";
import axios from "axios";
import VueAxios from "vue-axios";

import moment from "moment";
import VueLogger from "vuejs-logger";
import logOptions from "@/config/log";
import VueSimpleAlert from "vue-simple-alert";
import VueSweetalert2 from "vue-sweetalert2";
import VModal from "vue-js-modal";
import VueScrollTo from "vue-scrollto";
import VueApollo from "vue-apollo";

import { pluginShoppingBasket } from "@/lib/plugins/shoppingBasket";
import { useApolloClient } from "@/lib/apollo/client";
import { createPinia, PiniaVuePlugin } from "pinia";
import FatalError from "@/lib/components/error/FatalError";
import { createError } from "@/lib/components/error/createError";
import { setFatalError } from "@/lib/components/error/setFatalError";
import VueRouter from "vue-router";



library.add(...icons);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueSession, {
  persist: true
});
pluginShoppingBasket(Vue);
Vue.use(vueDebounce, {
  listenTo: ["input"]
});
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.prototype.$touriConfig = cfg;

Vue.prototype.$moment = moment;

Vue.use(VueAxios, axios);

Vue.use(VueLogger, logOptions);

Vue.use(VueSimpleAlert, {
  customClass: {
    confirmButton: "button button-primary button-tdays",
    cancelButton: "button button-primary button-tpics ml-3",
    popup: "alert-popup"
  },
  cancelButtonText: "Abbrechen",
  buttonsStyling: false
});

Vue.use(VueSweetalert2, {
  customClass: {
    confirmButton: "button button-primary button-tdays",
    cancelButton: "button button-primary button-tpics ml-3",
    popup: "alert-popup"
  },
  timer: 1500,
  cancelButtonText: "Abbrechen",
  buttonsStyling: false
});

Vue.use(VModal, { dynamicDefault: { height: "auto" } });

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

// Vue Filter
Vue.filter(VueFilter.formatDateFilter.name, VueFilter.formatDateFilter.func);
Vue.filter(
  VueFilter.formatDateTimeFilter.name,
  VueFilter.formatDateTimeFilter.func
);
Vue.filter(
  VueFilter.formatDatePickerValueFilter.name,
  VueFilter.formatDatePickerValueFilter.func
);
Vue.filter(
  VueFilter.formatDateWithDayFilter.name,
  VueFilter.formatDateWithDayFilter.func
);
Vue.filter(VueFilter.formatValueFilter.name, VueFilter.formatValueFilter.func);
Vue.filter(VueFilter.formatPriceFilter.name, VueFilter.formatPriceFilter.func);
Vue.filter(
  VueFilter.formatPercentageFilter.name,
  VueFilter.formatPercentageFilter.func
);
Vue.filter(VueFilter.escapeHTMLFilter.name, VueFilter.escapeHTMLFilter.func);

Vue.config.productionTip = false;
const apolloClient = useApolloClient({
  session: Vue.prototype.$session,
  logger: Vue.prototype.$log,
  onError: ({ networkError }) => {
    if (!networkError) return;
    if (!networkError.statusCode) {
      setFatalError(createError(
        "Die Verbindung wurde unterbrochen"
      ));
    } else if ([401, 403].includes(networkError?.statusCode)) {
      setFatalError(createError(
        "Dieser Bereich ist nur für registrierte Benutzer zugänglich"
      ));
    }
  }
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

Vue.use(VueApollo);
Vue.use(VueRouter);
Vue.use(Meta);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
  router,
  pinia,
  apolloProvider,
  render: (h) => {
    return h(App);
  },
  errorCaptured(err) {
    if (!(err instanceof FatalError)) return;
    setFatalError(err);
  }
}).$mount("#app");

// Initial page_view push for first load
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  event: "page_view",
  page_location: window.location.href,
  page_path: window.location.pathname + window.location.search,
  page_title: document.title
});
