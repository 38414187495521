import cfg from "@/config/config";

window.dataLayer = window.dataLayer || [];

// Initial Consent-Block (keine Zustimmung)
window.dataLayer.push({
  'event': 'default_consent',
  'gtm.consentInitialization': true
});

window.dataLayer.push({
  'event': 'gtag.consent',
  'consent': 'default',
  'ad_storage': 'denied',
  'analytics_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'wait_for_update': 500
});



(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = false;
    j.src =
        'https://gtm-metrics.touridat.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', cfg.external.google.gtag);

(function (d, i) {
    const iframe = d.createElement('iframe');
    iframe.src = `https://gtm-metrics.touridat.com/ns.html?id=${i}`;
    iframe.height = 0;
    iframe.width = 0;
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";

    const noscript = d.createElement('noscript');
    noscript.appendChild(iframe);

    d.body.insertBefore(noscript, d.body.firstChild);
})(document, cfg.external.google.gtag);
