import config from "@/config/config";
export const getUserId = () => window?.app?.user?.id || null;
import { sum } from "@/lib/helper/math";

/* global gtag */
export const getClientId = (callback) => {
  if (typeof gtag === "function") {
    gtag("get", config.external.google.measurementId, "client_id", function (clientId) {
      callback(clientId);
    });
  } else {
    const match = document.cookie.match(/_ga=GA1\.\d\.(\d+\.\d+)/);
    const clientId = match ? match[1] : null;
    callback(clientId);
  }
};

/* no-useless-escape */
export const getFbpFromCookie = () => {
  const match = document.cookie.match(/_fbp=(fb\.[\d.]+)/);
  return match ? match[1] : null;
};

export const trackEventS2S = (eventName, ecommerce = {}, meta = {}) => {
  getClientId((clientId) => {
    const userId = window?.app?.user?.id || null;

    // fallback für fbp automatisch hinzufügen
    const fullMeta = {
      ...meta,
      fbp: meta?.fbp || getFbpFromCookie()
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      client_id: clientId,
      user_id: userId,
      ecommerce: ecommerce,
      meta: fullMeta
    });
  });
};

export const getAffiliation = () => {
  return `touriDat ${config?.runMode === "app" ? "App" : "WebShop"}`;
};

const getValue = (item, multiplier = 1) => {
  if (!item?.CurrentPrice) return;
  return item.CurrentPrice * multiplier;
};
export const sendViewItemList = (items, options) => {
  try {
    options = options || {};
    const userId = window?.app?.user?.id || null;
    const eventData = {
      ecommerce: {
        item_list_id: options?.item_list_id,
        item_list_name: options?.item_list_name,
        user_id: userId,
        items: items.map((item) => {
          const args = {
            ...options,
            item_id: item.SystemItemId,
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_variant: item.Name,
            price: item.RegularPrice,
            quantity: 1,
            affiliation: getAffiliation()
          };
          if (item?.Host?.Name) args.item_name = item.Host.Name;
          return args;
        })
      }
    };
    trackEventS2S("view_item_list", eventData.ecommerce);
  } catch (e) {
    console.log(e);
  }
};
export const sendSelectItem = (item, options) => {
  try {
    options = options || {};
    const userId = window?.app?.user?.id || null;
    const args = {
      ...options,
      item_id: item.SystemItemId,
      discount:
        item.Discount ||
        item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
      item_variant: item.Name,
      price: item.RegularPrice,
      quantity: 1,
      affiliation: getAffiliation()
    };
    if (item?.Host?.Name) args.item_name = item.Host.Name;

    const eventData = {
      ecommerce: {
        item_list_id: options?.item_list_id,
        item_list_name: options?.item_list_id,
        user_id: userId,
        items: [args]
      }
    };
    trackEventS2S("select_item", eventData.ecommerce);
  } catch (e) {
    console.log(e);
  }
};
export const sendViewItem = (item, hostName) => {
  try {
    if (!item) {
      return;
    }
    const userId = window?.app?.user?.id || null;
    const eventData = {
      ecommerce: {
        value: getValue(item),
        currency: "EUR",
        user_id: userId,
        items: [
          {
            item_id: item.SystemItemId,
            item_name: item.CustomAttributes?.Custom_Hotel_Name || hostName,
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_variant: item.Name || item.CustomAttributes?.Custom_Product_Arrangement,
            price: item.RegularPrice,
            quantity: 1,
            affiliation: getAffiliation()
          }
        ]
      }
    };
    trackEventS2S("view_item", eventData.ecommerce);
  } catch (e) {
    console.log(e);
  }
};

export const sendAddToCart = (item) => {
  try {
    if (!item || !item.SystemItemId) {
      return;
    }
    const userId = window?.app?.user?.id || null;
    const eventData = {
      ecommerce: {
        value: getValue(item, item.Quantity || 1),
        currency: "EUR",
        user_id: userId,
        items: [
          {
            item_id: item.SystemItemId,
            item_name: item.HostName,
            quantity: item.Quantity || 1,
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_variant: item.Name,
            price: item.RegularPrice,
            affiliation: getAffiliation()
          }
        ]
      }
    };
    trackEventS2S("add_to_cart", eventData.ecommerce);
  } catch (e) {
    console.log(e);
  }
};

export const sendRemoveFromCart = (item) => {
  try {
    if (!item || !item.SystemItemId) return;
    const userId = window?.app?.user?.id || null;
    const eventData = {
      ecommerce: {
        value: getValue(item, item.Quantity || 1),
        currency: "EUR",
        user_id: userId,
        items: [
          {
            item_id: item.SystemItemId,
            quantity: item.Quantity || 1,
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_variant: item.Name,
            item_name: item.HostName,
            price: item.RegularPrice,
            affiliation: getAffiliation()
          }
        ]
      }
    };
    trackEventS2S("remove_from_cart", eventData.ecommerce);
  } catch (e) {
    console.log(e);
  }
};

export const sendViewCart = (cartItems, totalPrice) => {
  try {
    if (!cartItems?.length) {
      return;
    }
    const userId = window?.app?.user?.id || null;

    let gaItems = [];
    cartItems.forEach((i) =>
      gaItems.push({
        item_id: i.SystemItemId,
        item_name: i.HostName,
        item_variant: i.Name,
        price: i.RegularPrice,
        quantity: i.Quantity,
        discount:
          i.Discount || i.RegularPrice - (i.CurrentPrice || i.DiscountedPrice),
        affiliation: getAffiliation()
      })
    );
    const eventData = {
      ecommerce: {
        value: totalPrice || 0,
        currency: "EUR",
        user_id: userId,
        items: gaItems
      }
    };
    trackEventS2S("view_cart", eventData.ecommerce);
  } catch (e) {
    console.log(e);
  }
};

export const sendAddPaymentInfo = (items, totalPrice, paymentType) => {
  try {
    if (!items?.length || !paymentType) return;
    const userId = window?.app?.user?.id || null;
    const eventData = {
      ecommerce: {
        currency: "EUR",
        value: totalPrice || 0,
        payment_type: paymentType,
        user_id: userId,
        items: items.map((item) => {
          const data = {
            item_id: item.SystemItemId,
            affiliation: getAffiliation(),
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_list_id: "shopping_basket_items",
            item_list_name: "Shopping Basket Items",
            item_variant: item.Name,
            item_name: item.HostName,
            price: item.RegularPrice,
            quantity: item.Quantity || 1
          };
          if (item?.Host?.Name) data.item_name = item.Host.Name;
          return data;
        })
      }
    };
    trackEventS2S("add_payment_info", eventData.ecommerce);
  } catch (e) {
    console.log(e);
  }
};

export const sendAddShippingInfo = (items, shippingCost, type) => {
  try {
    if (!items?.length || !type) return;
    const userId = window?.app?.user?.id || null;
    const eventData = {
      ecommerce: {
        currency: "EUR",
        value: shippingCost,
        shipping_tier: type,
        user_id: userId,
        items: items.map((item) => {
          const data = {
            item_id: item.SystemItemId,
            item_variant: item.Name,
            item_name: item.HostName,
            affiliation: getAffiliation(),
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_list_id: "shopping_basket_items",
            item_list_name: "Shopping Basket Items",
            price: item.RegularPrice,
            quantity: item.Quantity || 1
          };
          if (item?.Host?.Name) data.item_name = item.Host.Name;
          return data;
        })
      }
    };
    trackEventS2S("add_shipping_info", eventData.ecommerce);
  } catch (e) {
    console.log(e);
  }
};

export const sendBeginCheckout = (items, totalPrice, coupon) => {
  try {
    if (!items?.length) return;
    const userId = window?.app?.user?.id || null;
    const eventData = {
      ecommerce: {
        currency: "EUR",
        value: totalPrice || 0,
        user_id: userId,
        items: items.map((item) => {
          const data = {
            item_id: item.SystemItemId,
            item_variant: item.Name,
            affiliation: getAffiliation(),
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_list_id: "shopping_basket_items",
            item_list_name: "Shopping Basket Items",
            price: item.RegularPrice,
            quantity: item.Quantity || 1
          };
          if (item.HostName) data.item_name = item.HostName;
          return data;
        })
      }
    };
    if (coupon) eventData.ecommerce.coupon = coupon;
    trackEventS2S("begin_checkout", eventData.ecommerce, { coupon });
  } catch (e) {
    console.log(e);
  }
};

export const sendPurchase = (order, coupon, ref) => {
  try {
    if (!order) return;
    const orderItems = order.OrderItems;
    if (!orderItems) {
      return;
    }
    const userId = window?.app?.user?.id || null;
    const eventData = {
      ecommerce: {
        currency: "EUR",
        transaction_id: order.OrderNumber,
        tax: 0,
        shipping: order.TotalShippingItemCosts,
        value: order.TotalItemPrice || 0,
        user_id: userId,
        items: orderItems.map((i) => {
          const data = {
            item_id: i.SystemItemId,
            item_variant: i.Name,
            item_shopId: i.ItemId,
            affiliation: getAffiliation(),
            currency: "EUR",
            discount: i.Item.Discount || i.Item.RegularPrice - (i.Item.CurrentPrice || i.Item.DiscountedPrice),
            price: i.Item.RegularPrice,
            quantity: i.Quantity || 1,
            item_list_id: "shopping_basket_items",
            item_list_name: "Shopping Basket Items",
          };
          if (i.HostName) data.item_name = i.HostName;
          return data;
        })
      }
    };
    if (coupon) eventData.ecommerce.coupon = coupon;
    trackEventS2S("purchase", eventData.ecommerce, {
      referrer: ref?.Name || "WebShop",
      referrerId: ref?.Value || "WebShop"
    });
  } catch (e) {
    console.log(e);
  }
};

export const sendSearch = (term) => {
  if (!term || !term?.trim()?.length) return;
  trackEventS2S("search", {}, { search_term: term });
};

export const sendLogin = () => {
  trackEventS2S("login", {}, { method: "login form" });
};

export const sendSignUp = () => {
  trackEventS2S("sign_up", {}, { method: "registration form" });
};

export const sendAddToWishlist = (items) => {
  try {
    if (!items?.length) return;
    const userId = window?.app?.user?.id || null;
    const eventData = {
      ecommerce: {
        currency: "EUR",
        value: sum(items.map((item) => getValue(item))),
        user_id: userId,
        items: items.map((item) => {
          const data = {
            item_id: item.SystemItemId,
            affiliation: getAffiliation(),
            discount:
              item.Discount ||
              item.RegularPrice - (item.CurrentPrice || item.DiscountedPrice),
            item_list_id: "favorite_host_items",
            item_list_name: "Favorite Host items",
            price: item.RegularPrice,
            quantity: item.Quantity || 1
          };
          if (item?.Host?.Name) data.item_name = item.Host.Name;
          return data;
        })
      }
    };
    trackEventS2S("add_to_wishlist", eventData.ecommerce);
  } catch (e) {
    console.log(e);
  }
};

export const sendVisitFromReferrer = (name) => {
  try {
    if (typeof name !== "string" || !name.trim().length) name = "WebShop";
    trackEventS2S("visit_from_referrer", {}, { referrer: name });
  } catch (e) {
    console.log(e);
  }
};
